import Link from "next/link";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.less";
import RetroTitle from "@/components/Common/RetroTitle";
import type { MarketplaceNavItem } from "@/components/Layouts";
import { NavItem, MobileHeader } from "@/components/Layouts";
import { AppSettings } from "@/shared/app-common";
import Image from "next/image";
import { Button, Dropdown, MenuProps, message } from "antd";
import { useUserProvider } from "@/context/UserProvider";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useConfigProvider } from "@/context/ConfigProvider";
import { ga_logEvent } from "@/shared/ga";
import { EventName } from "@/shared/event-types";
// import ChangeLanguage from "@/components/Common/Language";
import OurModal from "@/components/Modal";
import { Form, Input, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { feedback } from "@/lib/service";

interface GlobalHeaderProps {
  // isShopPage?: boolean;
  // isMarketplaceHome?: boolean;
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
  isStarData?: boolean;
}
type FieldType = {
  type?: string;
  content?: string;
};

const GlobalHeader = ({
  navItems,
  isMobile,
  isStarData,
}: GlobalHeaderProps) => {
  const { isLogin, onSignOut, userInfo } = useUserProvider();

  const { t } = useTranslation("common");
  const router = useRouter();
  const handleOpenModal = () => {
    setisModalOpen(true);
  };
  const [isModalOpen, setisModalOpen] = useState(false);
  const imageUrl = useRef("");
  const [confirmLoding, setconfirmLoding] = useState(false);

  const handleFinish = (e: any) => {
    let params = e;
    params.pic_list = [imageUrl.current];
    setconfirmLoding(true);
    feedback(params).then((_) => {
      setisModalOpen(false);
      setconfirmLoding(false);
      message.success("success");
    });
  };
  const handleUploadChange = (e: any) => {
    imageUrl.current = e.file.response?.data;
  };

  const items: MenuProps["items"] = [
    // {
    //   key: "3",
    //   label: <Link href={"/dashboard"}>Dashboard</Link>,
    // },
    {
      key: "4",
      label: <Link href={"/personal"}>Settings</Link>,
    },
    {
      key: "5",
      label: <span onClick={handleOpenModal}>problem feedback</span>,
    },
    {
      key: "6",
      label: (
        <Link
          href={"/"}
          onClick={() => {
            onSignOut();
            ga_logEvent(EventName.header_Logout);
          }}
        >
          {t("LogOut")}
        </Link>
      ),
    },
  ];

  const getNavLeftSection = useMemo(() => {
    // if (!!isMarketplaceHome) {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: "32px" }}>
                  <Image
                    className={styles.logo}
                    src={AppSettings.logoPath}
                    alt={AppSettings.name}
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                  <img
                    src={AppSettings.namePath}
                    alt={AppSettings.name}
                    style={{ height: "32px", marginLeft: "10px" }}
                  />
                  {/* <span className={styles.webname}>Copycobra.fast</span> */}
                </div>
              }
              // textColor="#ff401a"
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
  }, []);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      if (isMobile) {
        return navItems?.reduce(
          (acc: MarketplaceNavItem[], cur: MarketplaceNavItem) => {
            return cur?.dropdownItems
              ? [...acc, ...cur?.dropdownItems]
              : [...acc, cur];
          },
          [],
        );
      }
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames("", {
          "mobile-left-menu-item": isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems]);

  if (isMobile) {
    return <MobileHeader>{renderNavRightSection}</MobileHeader>;
  }

  return (
    <div
      className={styles.headerContainer}
      style={{ position: isStarData ? "absolute" : "fixed" }}
    >
      {/* {!isLogin && !router.pathname.includes("instagram-tracking-blog")
        ? UnloginHeader()
        : ""} */}
      <div
        style={{
          background: isStarData ? "transparent" : "var(--foreground-rgb)",
        }}
        className={classNames(styles.globalHeaderWarp)}
      >
        <div className={styles.navSection}>{getNavLeftSection}</div>
        <div className={styles.navSection} style={{ justifyContent: "center" }}>
          {renderNavRightSection}
        </div>

        <div className={styles.navSection}>
          {isLogin && router.pathname != "/login" ? (
            <>
              <Button
                className={styles.login}
                onClick={() => {
                  router.push("/dashboard");
                  ga_logEvent(EventName.header_Dashboard);
                }}
              >
                {t("Dashboard")}
              </Button>
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                autoAdjustOverflow={false}
                overlayClassName={styles.menus}
              >
                <span
                  onClick={() => router.push("/personal")}
                  className={styles.avatar}
                >
                  {userInfo?.first_name?.charAt(0) || "C"}
                </span>
              </Dropdown>
            </>
          ) : (
            <Button
              className={styles.login}
              onClick={() => {
                router.push("/login");
                ga_logEvent(EventName.header_SignIn);
              }}
            >
              {t("SignIn_SignUp")}
            </Button>
          )}
          {/* <ChangeLanguage /> */}
        </div>
      </div>
      <OurModal
        isModalOpen={isModalOpen}
        handleOk={() => {}}
        handleCancel={() => setisModalOpen(false)}
        width={500}
      >
        <div className={styles.p_con}>
          <div>problem feedback</div>
          <div></div>
          <div className={styles.p_f_con}>
            <Form
              name="basic"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={handleFinish}
              onFinishFailed={() => {}}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item<FieldType>
                label={<span style={{ color: "#ffffff" }}>Feedback type</span>}
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please change your question type!",
                  },
                ]}
              >
                <Select placeholder="Question type">
                  <Select.Option value="Bug/Error">Bug/Error</Select.Option>
                  <Select.Option value="Feature Request">
                    Feature Request
                  </Select.Option>
                  <Select.Option value="User Experience">
                    User Experience
                  </Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item<FieldType>
                label={
                  <span style={{ color: "#ffffff" }}>Problem description</span>
                }
                name="content"
                rules={[
                  {
                    required: true,
                    message: "Please input your question description!",
                  },
                ]}
              >
                <Input.TextArea rows={5} placeholder="Question Description" />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: "#ffffff" }}>image upload</span>}
                valuePropName="fileList"
              >
                <Upload
                  action={
                    process.env.NEXT_APP_API_HOST +
                    "/cobra/content/upload/image"
                  }
                  onChange={handleUploadChange}
                  maxCount={1}
                  headers={{
                    Authorization: Cookies.get("userToken"),
                  }}
                  listType="picture-card"
                >
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    <PlusOutlined style={{ color: "#ffffff" }} />
                    <div style={{ marginTop: 8, color: "#ffffff" }}>Upload</div>
                  </button>
                </Upload>
              </Form.Item>
              <div className={styles.btn_c}>
                <Form.Item>
                  <Button
                    className={styles.cancel}
                    onClick={() => setisModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={styles.confirm}
                    htmlType="submit"
                    loading={confirmLoding}
                  >
                    Confirm
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </OurModal>
    </div>
  );
};

export default GlobalHeader;

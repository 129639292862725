import { useEffect, useState, type ReactNode } from "react";
import Link from "next/link";
import { NavHamburger, NavMobileSearch } from "@/components/Layouts";
import styles from "./index.module.less";
import { AppSettings } from "@/shared/app-common";
import ChangeLanguage from "@/components/Common/Language";
import Image from "next/image";
import { useRouter } from "next/router";
import { useUserProvider } from "@/context/UserProvider";
import { LeftOutlined } from "@ant-design/icons";

const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  const router = useRouter();
  const { isLogin, userInfo } = useUserProvider();
  const [isLgi, setisLgi] = useState(false);

  const getTitle = (): any => {
    let pathname = router.pathname;
    let arr = [
      {
        url: "mobile-clew-confirm",
        desc: "CONFIRM REMOVAL",
      },
      {
        url: "mobile-addClew",
        desc: "ADD CLUES TO DELETE",
      },
      {
        url: "personal",
        desc: "SETTING",
      },
      {
        url: "profile",
        desc: "SETTING",
      },
      {
        url: "mobile-content",
        desc: "Detail Page",
      },
    ];
    let index = arr.findIndex((item) => pathname.includes(item.url));
    return index == -1 ? -1 : arr[index];
  };
  useEffect(() => {
    setisLgi(isLogin);
  }, [isLogin]);
  return (
    <div className={styles.mobileHeaderWarp}>
      {getTitle() == -1 ? (
        <div className={styles.navToolbar}>
          <div className={styles.navToolbarSection}>
            <NavHamburger>{children}</NavHamburger>
          </div>

          <div className={styles.navToolbarSection}>
            <Link href="/" className={styles.logo}>
              <Image
                className={styles.pietra}
                src={AppSettings.logoPath}
                alt={AppSettings.name}
                title={AppSettings.name}
                width={32}
                height={32}
              />
              <img
                src={AppSettings.namePath}
                alt={AppSettings.name}
                style={{ height: "32px", marginLeft: "10px" }}
                loading="lazy"
                width={154}
                height={32}
              />
            </Link>
          </div>

          <div className={styles.navToolbarSection}>
            {isLgi ? (
              <div
                className={styles.avatar}
                onClick={() => router.push("/profile")}
              >
                {userInfo?.first_name?.charAt(0) || "C"}
              </div>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => router.push("/login")}
              >
                Login in
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.navToolbar}>
          <div
            className={styles.navToolbarSection}
            onClick={() => router.back()}
          >
            <LeftOutlined />
          </div>

          <div className={styles.desc}>{getTitle().desc}</div>

          <NavHamburger
            iNode={
              <div className={styles.navToolbarSection}>
                <div
                  style={{
                    height: "2rem",
                    width: "2rem",
                    textAlign: "center",
                    lineHeight: "2rem",
                    backgroundColor: "var(--app-primary-color)",
                    borderRadius: "2rem",
                  }}
                >
                  {userInfo?.first_name?.charAt(0) || "C"}
                </div>
              </div>
            }
          >
            {children}
          </NavHamburger>
        </div>
      )}
    </div>
  );
};
export default MobileHeader;

{
  /* <div>Login in</div> */
}
{
  /* <NavMobileSearch className={styles.searchIcon} /> */
}
{
  /* <ChangeLanguage /> */
}

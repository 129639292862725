import React, { useEffect } from "react";
import ArticleDetailUI from "./ContentUI";
import { useConfigProvider } from "@/context/ConfigProvider";
import { useRouter } from "next/router";

const ArticleDetail = ({ data }: { data: any }) => {
  return (
    <ArticleDetailUI
      model={data["model"]}
      // gptsList={data['gptsList']}
      articleList={data["articleList"]}
      contentData={data["contentData"]}
      recommendTags={data["recommendTags"]}
    />
  );
};

export default ArticleDetail;

import React, { ReactNode } from "react";
import styles from "./index.module.less";
import { useRouter } from "next/router";
import { useConfigProvider } from "@/context/ConfigProvider";

export const CommonBackContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return (
    <CommonBaseBackContent style={style}>
      <CommonBaseContent>{children}</CommonBaseContent>
    </CommonBaseBackContent>
  );
};

export const CommonBaseBackContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return (
    <div className={styles.mainBackContent} style={style}>
      {children}
    </div>
  );
};

export const CommonBaseContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  const router = useRouter();
  const { isMobile } = useConfigProvider();
  return (
    <div
      className={styles.mainContent}
      style={{
        ...style,
        padding: router.pathname.includes("/plan") && isMobile ? "0" : "1rem",
      }}
    >
      {" "}
      {children}
    </div>
  );
};

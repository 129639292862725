import React, { useEffect, useRef } from "react";
// import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from "@/components/Layouts";
import { useConfigProvider } from "@/context/ConfigProvider";
import classNames from "classnames";
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import "./index.less";
import { AppSettings } from "@/shared/app-common";
import { useTranslation } from "next-i18next";
import { EventName } from "@/shared/event-types";
import { useRouter } from "next/router";
// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
}: LayoutProps) => {
  // const { pathname } = useRouter();
  const { isMobile, isBlogWeb } = useConfigProvider();
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation("common");
  // startData和其他样式区分
  const router = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
              if (router.pathname.includes("starData")) {
                const src = isMobile
                  ? "/images/layouts/starBgkMob.webp"
                  : "/images/layouts/starBgk.webp";
                ref.current.style.backgroundImage = `url(${src})`;
                ref.current.style.marginTop = "0px";
              } else {
                ref.current.style.backgroundImage = `url('/images/layouts/background-icon.png')`;
                ref.current.style.marginTop = "62px";
              }
              if (!showHeader) {
                ref.current.style.marginTop = "0px";
              }
            }
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [router.pathname.includes("starData"), showHeader]);

  // app sub title
  const getNavItems = () => {
    switch (AppSettings.type) {
      case "15":
        return [
          {
            text: t("home"),
            path: "/",
            // path: AppSettings.webHost,
            gaName: EventName.header_Home,
          },
          {
            text: t("Pricing"),
            path: "/plan",
            gaName: EventName.header_Plan,
          },
          // {
          //   text: t("Analytics Sample"),
          //   path: isBlogWeb
          //     ? `${AppSettings.webHost}/instagram-tracking-example`
          //     : `/instagram-tracking-example`,
          //   gaName: EventName.header_Analytics_Sample,
          // },
          // {
          //   text: t("Celebrity Influencers"),
          //   path: isBlogWeb ? `${AppSettings.webHost}/starData` : `/starData`,
          //   gaName: EventName.header_star_data,
          // },
          {
            text: t("blog"),
            path: `/blog`,
            gaName: EventName.header_Blog,
          },
        ];

      default:
        [{}];
    }
  };

  return (
    <div className={"layout"}>
      {head}
      {showHeader && (
        <header className={"header"}>
          <GlobalHeader isMobile={isMobile} navItems={[...getNavItems()]} />
        </header>
      )}
      <main
        ref={ref}
        // style={suitData()}
        className={classNames("main", {
          mobile: isMobile,
        })}
      >
        {children}
      </main>
      {showFooter && (
        <footer className={"footer"}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;

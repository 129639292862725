let urls = [
  {
    name: "youtube",
    backgroundColor: "#E75151",
    imgUrl: "1-ytb",
    color: "#ffffff",
  },
  {
    name: "facebook",
    backgroundColor: "#3D7FFF",
    imgUrl: "2-fb",
    color: "#ffffff",
  },
  {
    name: "etsy",
    backgroundColor: "#F85B05",
    imgUrl: "3-etsy",
    color: "#ffffff",
  },
  {
    name: "x",
    backgroundColor: "#FFFFFF",
    imgUrl: "4-x",
    color: "#000000",
  },
  {
    name: "instagram",
    backgroundColor: "#D54063",
    imgUrl: "5-ins",
    color: "#ffffff",
  },
  {
    name: "flickr",
    backgroundColor: "#1D63DB",
    imgUrl: "6-flicker",
    color: "#ffffff",
  },
  {
    name: "tumblr",
    backgroundColor: "#113258",
    imgUrl: "7-tumblr",
    color: "#ffffff",
  },
  {
    name: "reddit",
    backgroundColor: "#F64402",
    imgUrl: "8-reddit",
    color: "#ffffff",
  },
  {
    name: "amazon",
    backgroundColor: "#ffffff",
    imgUrl: "9-amazon",
    color: "#000000",
  },

  {
    name: "ebay",
    backgroundColor: "#ffffff",
    imgUrl: "10-ebay",
    color: "#000000",
  },
  {
    name: "Medium",
    backgroundColor: "#ffffff",
    imgUrl: "11-Medium",
    color: "#000000",
  },
  {
    name: "TikTok",
    backgroundColor: "#444444",
    imgUrl: "12-ytb",
    color: "#ffffff",
  },

  {
    name: "onlyfans",
    backgroundColor: "#32ADEF",
    imgUrl: "13-onlyfans",
    color: "#ffffff",
  },
];
export function getPlatefromByUrl(url: string) {
  if (!url) {
    return {
      name: "other",
      backgroundColor: "#ffffff",
      imgUrl: "14-other",
      color: "#000000",
    };
  }
  let arr = urls.find((item) => {
    return url.includes(item.name);
  });
  if (!arr) {
    return {
      name: "other",
      backgroundColor: "#ffffff",
      imgUrl: "14-other",
      color: "#000000",
    };
  } else {
    return arr;
  }
}

import OurModal from "../index";
import styles from "./index.module.less";
import React, { useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { orderSubsRequest } from "@/lib/service";

const Index: React.FC<any> = ({
  isTgModal,
  setisTgModal,
  userUseTimes,
  width,
}) => {
  const stripe = useStripe();
  let client_secret = useRef("");
  const [ponitNeedCount, setponitNeedCount] = useState(1);
  const handleEdit = (number: number) => {
    if (ponitNeedCount == 1 && number == -1) {
      return;
    }
    setponitNeedCount(ponitNeedCount + number);
  };
  const loadScripePay = async () => {
    const _ = await stripe?.redirectToCheckout({
      sessionId: client_secret.current,
    });
  };
  const handlePay = () => {
    orderSubsRequest({ item_id: 7, amount: ponitNeedCount }).then((res) => {
      client_secret.current = res.data?.client_secret;
      loadScripePay();
    });
  };

  return (
    <OurModal
      isModalOpen={isTgModal}
      handleCancel={() => {
        setisTgModal(false);
      }}
      handleOk={() => {}}
      width={width || 450}
    >
      <div className={styles.noPoint}>
        <div className={styles.np_title}>Hosting Site Removal</div>
        <div className={styles.np_desc}>
          <span>$10</span>
          <span>/1 unit(includes 10 managed removals)</span>
        </div>
        <div className={styles.np_action}>
          <div onClick={() => handleEdit(-1)}>-</div>
          <div>
            <div>
              <span>{ponitNeedCount}</span>
              <span>unit</span>
            </div>
            <div>Purchase quantity</div>
          </div>
          <div onClick={() => handleEdit(+1)}>+</div>
        </div>
        <div className={styles.np_point}>
          <span>Remaining takedowns:</span>
          <span>
            {userUseTimes?.subscription_site_total_num +
              userUseTimes?.one_time_site_remove_num -
              userUseTimes?.site_num}
            + {10 * ponitNeedCount}
          </span>
        </div>
        <div className={styles.np_buy} onClick={handlePay}>
          Purchase
        </div>
        <div className={styles.np_b_desc}>
          Membership is required to purchase
        </div>
      </div>
    </OurModal>
  );
};

const ClewPageIndex: React.FC<any> = (data) => {
  const isProd =
    process.env.NODE_ENV === "production" &&
    !process.env.NEXT_APP_API_HOST.includes("-test.");

  const stripePromise = loadStripe(
    isProd
      ? "pk_live_51Pdl8vHJdRPW3bUyKKItLEtEwh9rxaOwXVj7ine0bWuDNIQAPZ3TnOxeNuKBbORyOWxPMXjVvAFH9MpN2Hc5QLJu00PXAUhS1G"
      : "pk_test_51Pdl8vHJdRPW3bUyyYJBzVOmylN32KZijVxnACBWAccR38LBBLg3UKw7fx3mDLrLmguA0PKrCqnvwS3fJnTAUCmp00a6NqdtFW",
  );

  return (
    <Elements stripe={stripePromise}>
      <Index {...data} />
    </Elements>
  );
};

export default ClewPageIndex;

import React, { useEffect } from "react";
import styles from "./index.module.less";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
interface Iprops {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  children: React.ReactElement;
  width?: number;
  top?: string;
}
const Index: React.FC<Iprops> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  width,
  top,
}) => {
  useEffect(() => {
    let html = document.getElementsByTagName("html")[0];
    if (isModalOpen) {
      html.style.overflowY = "hidden";
    } else {
      html.style.overflowY = "";
    }
  }, [isModalOpen]);
  return (
    <div>
      {isModalOpen ? (
        <div className={styles.container}>
          <div
            className={styles.main}
            style={{ width: width, marginTop: top || "10rem" }}
          >
            {/* {children} */}
            <div className={styles.icon}>
              <CloseOutlined
                color="#ffffff"
                onClick={() => handleCancel()}
              ></CloseOutlined>
            </div>
            {children}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Index;
